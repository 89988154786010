import axios from "axios";
import {singleton} from "../utils/singleton";
import {notificationError} from "../utils/notification-error";
import Cookies from "js-cookie";

const api = `/api/v1/products`
const token = Cookies.get('hitno_token')

export const searchCite = async ({countryId, parent, name}) => {
    try {
        return await axios.get(`${api}/address/city`, {
            params: {
                country_id: countryId,
                parent: parent,
                name: name
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'x-singleton-hitno': singleton(`${api}/address/city`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

export const searchProvince = async ({countryId, name}) => {
    try {
        return await axios.get(`${api}/address/province`, {
            params: {
                country_id: countryId,
                name: name
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'x-singleton-hitno': singleton(`${api}/address/province`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

export const createOrder = async ({
                                      cost,
                                      currencyCode,
                                      name,
                                      surname,
                                      country,
                                      province,
                                      city,
                                      street,
                                      house,
                                      apartmentNumber,
                                      postalCode,
                                      codePhone,
                                      phoneNumber,
                                      note,
                                      isDeliverySave,
                                      products
                                  }) => {
    try {
        return await axios.post(`${api}/product/new-order`, {
            cost: cost,
            currency_code: currencyCode,
            name,
            surname,
            country,
            province,
            city,
            street,
            house,
            apartment_number: apartmentNumber,
            postal_code: postalCode,
            code_phone: codePhone,
            phone_number: phoneNumber,
            note,
            is_delivery_save: isDeliverySave,
            products
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-singleton-hitno': singleton(`${api}/product/new-order`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProductsComplaint = async ({productId, description}) => {
    try {
        return await axios.post(`${api}/complaint`, {
            product_id: productId,
            description: description
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-singleton-hitno': singleton(`${api}/complaint`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProductsEndlessLoading = async ({take, skip, countryId}) => {
    try {
        const preporuk = document.getElementById('preporuk-sliders')

        return await axios.get(`${api}/endless-loading`, {
            params: {
                take,
                skip,
                country_id: countryId,
                locale: (preporuk.hasAttribute('data-locale')) ? preporuk.dataset.locale : null
            },
            headers: {
                'x-singleton-hitno': singleton(`/api/v1/products/endless-loading`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

export const getProductsSaleEndlessLoading = async ({take, skip, countryId}) => {
    try {
        const preporuk = document.getElementById('preporuk-sliders')

        return await axios.get(`${api}/sale/endless-loading`, {
            params: {
                take,
                skip,
                country_id: countryId,
                locale: (preporuk.hasAttribute('data-locale')) ? preporuk.dataset.locale : null
            },
            headers: {
                'x-singleton-hitno': singleton(`/api/v1/products/sale/endless-loading`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postProductFavorite = async ({productId}) => {
    try {
        return await axios.post(`/api/v1/profile/favorite/toggle/product`, {
            product_id: productId
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-singleton-hitno': singleton(`/api/v1/profile/favorite/toggle/product`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postProductReviews = async ({page, productId, countryId, categoryId, sort, tag}) => {
    try {
        return await axios.post(`${api}/reviews/paginate`, {
            page: page,
            product_id: productId,
            country_id: countryId,
            category_id: categoryId,
            sort: sort,
            tag: tag,
        }, {
            headers: {
                'x-singleton-hitno': singleton(`${api}/reviews/paginate`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postProductSKU = async ({sku, productId, countryCode, quantity = 1, signal}) => {
    try {
        let config = {
            headers: {
                'x-singleton-hitno': singleton(`${api}/sku`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        }

        if (signal) {
            config.cancelToken = signal
        }

        return await axios.post(`${api}/sku`, {
            sku,
            product_id: productId,
            country_code: countryCode,
            quantity: quantity
        }, config)
    } catch (error) {
        console.log(error)
        //notificationError()
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postProductView = async ({productId, categoryId, userId}) => {
    try {
        return await axios.post(`${api}/view`, {
            product_id: productId,
            category_id: categoryId,
            user_id: userId
        }, {
            headers: {
                'x-singleton-hitno': singleton(`${api}/view`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        //notificationError()
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postFilterProductsCount = async ({categoryId, subcategoryId, subsubcategoryId, json, signal}) => {
    try {
        return await axios.post(`${api}/filter/count`, {
            category_id: Number(categoryId),
            subcategory_id: Number(subcategoryId),
            subsubcategory_id: Number(subsubcategoryId),
            json: json,
            country_id: Cookies.get('hitno_country')
        }, {
            cancelToken: signal,
            headers: {
                'x-singleton-hitno': singleton(`${api}/filter/count`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        //notificationError()
    }
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postFilterProducts = async ({categoryId, subcategoryId, subsubcategoryId, json, sort, page}) => {
    try {
        const category = document.getElementById('category')

        return await axios.post(`${api}/filter`, {
            category_id: Number(categoryId),
            subcategory_id: Number(subcategoryId),
            subsubcategory_id: Number(subsubcategoryId),
            json: json,
            sort: sort,
            page: Number(page),
            country_id: Cookies.get('hitno_country'),
            locale: (category.hasAttribute('data-locale')) ? category.dataset.locale : null
        }, {
            headers: {
                'x-singleton-hitno': singleton(`${api}/filter`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}

export const postProvinceList = async ({countryId, signal}) => {
    try {
        return await axios.get(`${api}/address/list/province`, {
            params: {
                country_id: countryId
            },
            cancelToken: signal,
            headers: {
                Authorization: `Bearer ${token}`,
                'x-singleton-hitno': singleton(`${api}/address/list/province`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
    }
}

export const postCityList = async ({countryId, provinceId, signal}) => {
    try {
        const config = {
            params: {
                country_id: countryId,
                province_id: provinceId
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'x-singleton-hitno': singleton(`${api}/address/list/city`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        }

        if (signal) {
            config.cancelToken = signal
        }

        return await axios.get(`${api}/address/list/city`, config)
    } catch (error) {
        console.log(error)
    }
}

export const postRefreshPay = async ({orderId}) => {
    try {
        return await axios.post(`${api}/product/pay`, {
            order_id: orderId,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-singleton-hitno': singleton(`${api}/product/pay`),
                'Accept-Language': document.querySelector('html').getAttribute('lang')
            }
        })
    } catch (error) {
        console.log(error)
        notificationError()
    }
}























